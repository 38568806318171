const TableUrl = (payload, tableDetails, interAPi) => {
    if(interAPi){
        return {
            "url": `${process.env.VUE_APP_COMMON_SERVICES_TABLE}common/claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else if(tableDetails.table_url=='searchRuntimeFieldsConfig'){
        return {
            "url": `${process.env.VUE_APP_CLAIM_TABLE}claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else if(tableDetails.table_url=='getOutstandingReport'){
        return {
            "url": `${process.env.VUE_APP_COMMON_SERVICES_TABLE}common/report/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
   
    else{
        return {
            "url": `${process.env.VUE_APP_CASE_SERVICES_TABLE}claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
}

const TableConfigUrl = (payload, tableDetails, interAPi='') => {
    console.log('interAPi', tableDetails.table_url)
    if(interAPi){
        return {
            "url": `${process.env.VUE_APP_CASE_CONFIG_TABLE}config/${interAPi}/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else if(tableDetails.table_url=='searchRuntimeFieldsConfig'){
        return {
            "url": `${process.env.VUE_APP_CLAIM_TABLE}claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else{
        return {
            "url": `${process.env.VUE_APP_CASE_CONFIG_TABLE}config/user/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    
}



const DocumentTableUrl = (payload, tableDetails) => {
    return {
        "url": `${process.env.VUE_APP_DOCUMENT_TABLE}document/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
    }
}


const setTheme = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}configuration/api/commonConfig`,
        "mutation": "SET_THEME",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}

const getMenuPages = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchMenuPages`,
        "mutation": "GET_MENU_PAGES",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}



const GetFormPage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPage`,
        "mutation": "GET_FORM_PAGE",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const GetPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "requests": {}
    }
}

const PopUpPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "requests": {}
    }
}



const GetDropDownAPIs = (fieldName) => {
    let apiHolder = [{ 
        "lineOfBusiness":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLOBList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "claimStatus":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchClaimStatusMaster`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "causeOfLoss":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchCauseOffLoss`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "documentName":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchDocumentName`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
    }]
    if(fieldName.includes('__')){
        let splitName = fieldName.split('__')
        if(splitName && splitName.length && (splitName.length>1) && (typeof apiHolder[0][splitName[1]] != "undefined")){
            return  apiHolder[0][splitName[1]]
        }
    }
    else if(typeof apiHolder[0][fieldName] != "undefined"){
        return  apiHolder[0][fieldName]
    }
    else{
        return ''
    }
}

const LookupByType = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLookUpByType`,
        "mutation": "GET_LOOKUP_VALUES",
        "send_payload": true,
        "notify_message": "Success",
        "requests": {}
    }
}

const MasterLookupByType = (masterAPI) => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/${masterAPI}`,
        "mutation": "GET_MASTER_VALUES",
        "notify_message": "Success",
        "requests": {}
    }
}



const GetPincodeCode = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/master/api/fetchLocationByPincode`,
        "mutation": "GET_PINCODE_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}

const UserCount = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/report/api/fetchUserCount`,
        "mutation": "GET_USER_COUNT",
        "notify_message": "Success",
        "requests": {}
    }
}

const UserSummaryCount = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/report/api/fetchClaimSummaryCount`,
        "mutation": "GET_SUMMARY_COUNT",
        "notify_message": "Success",
        "requests": {}
    }
}


const ReplicateUser = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveReplicateUser`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}


const SaveFlashMessage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/home/api/saveFalshMessage`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveClientConfiguration = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/saveRuntimeFields`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveMenuAccess = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveUserAccess`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveHierarchyPreference = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveHierarchyPreference`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}



const SaveHierarchyUserDetails = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveHierarchyUserDetails`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}



const GetTablePageData = (pageName) => {
    console.log('GetTablePageData', )
    let url = '';
    if(pageName=='userTable'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/userSearch`; 
    }
    else if(pageName=='flashMessage'){
        url = `${process.env.VUE_APP_CONFIG}config/home/api/fetchFalshMessageList`; 
    }
    else if(pageName=='clientManagement'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/fetchHierarchyUserDetails`; 
    }
    else if(pageName.trim()=='userHierarchy'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/fetchHierarchyPreference`; 
    }
    else if(pageName.trim()=='clientFields1'){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/searchRuntimeFieldsConfig`; 
    }

    return {
        "url": `${url}`,
        "mutation": "GET_TABLE_PAGE_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}
const FetchPageData = (pageName) => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/${pageName}`,
        "mutation": "GET_PAGE_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}


const DownloadDocument = () => {
    return {
        "url": `${process.env.VUE_APP_DOWNLOAD_SERVICES}document/api/downloadDocument`,
        "mutation": "GET_DOWNLOAD_DOCUMENT",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const FlashMessage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/home/api/fetchFlashMessages`,
        "mutation": "GET_FLASH_MESSAGE",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const fetchTemplateDocList = () => {
    return {
        "url": `${process.env.VUE_APP_DOWNLOAD_SERVICES}document/api/fetchTemplateDocList`,
        "mutation": "GET_DOC_LIST_DASHBOARD",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const FetchDashboardChartData = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/report/api/fetchClaimAnalysisCount`,
        "mutation": "GET_DASHBOARD_CHART",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}


const fetchUserHierarchy = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchUserHierarchyDetails`,
        "mutation": "GET_HIERERACHY_DASHBOARD",
        "send_payload": true,
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const SaveForm = (apiName) => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/${apiName}`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveFormDetails = (apiName, pageName) => {
    let url = '';
    if(apiName && (pageName && pageName.trim()=="system-configuration")){
        url = `${process.env.VUE_APP_CONFIG}config/user/api/${apiName}`
    }

    let finalResult = {
        "url": url,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
    return finalResult;
}

const UserProfile = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchUserProfile`,
        "mutation": "GET_USER_PROFILE",
        "notify_message": "Success",
        "requests": {}
    }
}

const FormUploadDocument = (details) => {
     return {
        "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/${(details) ? details : 'uploadTemplateDocument'}`,
        "mutation": "FORM_UPLOAD_DOCUMENT",
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const FormUploadClaimDocument = (details) => {
    return {
        "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/${(details) ? details : 'uploadDocument'}`,
        "mutation": "FORM_UPLOAD_DOCUMENT",
        "notify_message": "Success",
        "encryption":"true",
        "requests": {}
    }
}

const UpdateUserProfile = () => {
    
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveUserProfile`,
        "mutation": "UPDATE_USER_PROFILE",
        "notify_message": "Success",
        "requests": {}
    }
}
 

export {
    setTheme,
    getMenuPages,
    GetFormPage,
    UserProfile,
    GetPageFields,
    LookupByType,
    FetchPageData,
    MasterLookupByType,
    GetDropDownAPIs,
    TableUrl,
    DocumentTableUrl,
    SaveForm,
    SaveFormDetails,
    FormUploadDocument,
    FormUploadClaimDocument,
    DownloadDocument,
    TableConfigUrl,
    PopUpPageFields,
    GetPincodeCode,
    UserCount,
    UserSummaryCount,
    GetTablePageData,
    FlashMessage,
    fetchTemplateDocList,
    fetchUserHierarchy,
    ReplicateUser,
    SaveFlashMessage,
    SaveHierarchyPreference,
    SaveHierarchyUserDetails,
    SaveMenuAccess,
    SaveClientConfiguration,
    FetchDashboardChartData,
    UpdateUserProfile
}