import Vue from 'vue';
import Router from 'vue-router'
import Login from '@/container/auth/login'
import menus from './router'
import store from '../store'
import _ from 'lodash'
Vue.use(Router);
let get_full_menu = (list_sub_menu) =>{
  
    if(list_sub_menu.children && list_sub_menu.children.length>=1){
            let _sub_menus =   _.map(list_sub_menu.children, (child_menu) => {
            if(child_menu.children && child_menu.children.length<=0){
                
                return {
                    path: child_menu.path,
                    name: child_menu.name,
                    component: () => import (/* webpackChunkName: "[request]" */`@/${child_menu.filePath}`),
                    meta: { requiresAuth: child_menu.auth, roles: child_menu.roles ? child_menu.roles : []}
                }
            }
            else{
                return  get_full_menu(child_menu);
            }
            
        })
        return {
            path: list_sub_menu.path,
            name: list_sub_menu.name,
            children : _sub_menus,
            component: () => import (/* webpackChunkName: "[request]" */`@/${list_sub_menu.filePath}`),
            meta: { requiresAuth: list_sub_menu.auth, roles: list_sub_menu.roles ? list_sub_menu.roles : []}
        }
    }
    else{
        return {
            path: list_sub_menu.path,
            name: list_sub_menu.name,
            component: () => import (/* webpackChunkName: "[request]" */`@/${list_sub_menu.filePath}`),
            meta: { requiresAuth: list_sub_menu.auth , roles: list_sub_menu.roles ? list_sub_menu.roles : []}
        }
    }
}
let _menus = menus.menus();


let main_menu = _.map(_menus, (list_menu) => {
    if (list_menu.path == "*") {
        return {
            path: list_menu.path,
            name: list_menu.name,
            redirect: '/404'
        }
    } else {
        let _sub_menu = [];
        if (list_menu.children && list_menu.children.length) {
            _sub_menu = _.map(list_menu.children, (list_sub_menu) => {
                    return get_full_menu(list_sub_menu);
            })
        }
        return {
            path: list_menu.path,
            name: list_menu.name,
            component: () => import (/* webpackChunkName: "[request]" */`@/${list_menu.filePath}`),
            children: _sub_menu,
            meta: { requiresAuth: list_menu.auth }
        }
    }
})
main_menu.push({
    path: '/login',
    name: 'Login',
    component: Login
})

var routes = new Router({
    mode : 'hash',
    linkExactActiveClass: 'active',
    routes: main_menu,
})
routes.beforeEach((to,from,next) => {
    next()
    console.log("login", from.name, store.state.auth)

    if(to.matched.some(record => record.meta.requiresAuth)) { 
        if(from.name == null){
            // window.location.replace('/')
            next()
        }
        else if (store.state.auth && store.state.auth.auth) {
            next()
        } 
        else{
            const loginUrl = routes.app.$keycloak.createLoginUrl()
            window.location.replace(loginUrl)
        }
    } 
    else {
        console.log("hgh")
      next()
    }
})
export default routes;