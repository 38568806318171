const PostUiLogs = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}log/api/saveUILogs`,
        "mutation": "POST_UI_LOGS",
        "notify_message": "Success",
        "encryption": "false",
        "requests": {}
    }
}

export {
    PostUiLogs
}
