<template>
    <div>
       
    </div>
</template>

<script>
    export default {
        name: 'LayoutView',
    }
</script>
