export default {
    menus () {
        return [{
                "id": "HOME",
                "path": "/",
                "name": "home",
                "filePath": "container/pages/Layout",
                "auth": true,
                "children": [
                {
                    "id": "HOME",
                    "path": "/",
                    "name": "home",
                    "filePath": "container/pages/HomePage",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                    {
                    "id": "DASHBOARD",
                    "path": "/dashboard",
                    "name": "Dashboard",
                    "filePath": "container/pages/DashboardPage",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "HOME",
                    "path": "/home",
                    "name": "home",
                    "filePath": "container/pages/HomePage",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
               
                
                {
                    "id": "NOTIFICATION",
                    "path": "/notification",
                    "name": "Notification",
                    "filePath": "container/pages/Notification",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Notification'
                    }
                },
                {
                    "id": "WBDETAILS",
                    "path": "/whistle-blower-details",
                    "name": "WbDetails",
                    "filePath": "container/pages/WhistleBlowerDetails",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Cases List'
                    }
                },
                {
                    "id": "ADVANCEDSEARCH",
                    "path": "/advanced-search",
                    "name": "AdvancedSearch",
                    "filePath": "container/pages/Cases",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Cases List'
                    }
                },
                {
                    "id": "UNIVERSALSEARCH",
                    "path": "/universal-search",
                    "name": "UniversalSearch",
                    "filePath": "container/pages/UniversalSearch",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Cases List'
                    }
                },
                {
                    "id": "ADVANCEDSEARCHPARAMS",
                    "path": "/advanced-search/:id",
                    "name": "AdvancedSearchParams",
                    "filePath": "container/pages/Cases",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Cases List'
                    }
                },
                {
                    "id": "UNIVERSALSEARCHPARAMS",
                    "path": "/universal-search/:id",
                    "name": "UniversalSearchParams",
                    "filePath": "container/pages/UniversalSearch",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Cases List'
                    }
                },
                {
                    "id": "CASE_HISTORY",
                    "path": "/case-history",
                    "name": "CasesHistory",
                    "filePath": "container/pages/CaseHistory",
                    "auth" : true,
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Cases History'
                    }
                },
                {
                    "id": "PROCEED_FOR_ACTION",
                    "path": "/proceed-for-action",
                    "name": "ProceedForAction",
                    "filePath": "container/pages/proceedAction",
                    "auth" : true,
                    "roles":[],
                    "meta": {
                        "breadcrumb": 'Proceed For Action'
                    }
                },
                
                {
                    "id": "ClaimDocumentUpload",
                    "path": '/claim-document-upload',
                    "name": "claim-document-upload",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "Others",
                    "path": '/others/:page_name',
                    "name": "OthersPage",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "ConfigurationPage",
                    "path": '/configuration/:page_name',
                    "name": "ConfigurationPage",
                    "filePath": "container/pages/MultipleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },


                {
                    "id": "ClaimDocumentUploadList",
                    "path": '/claim-document-upload-list',
                    "name": "claim-document-upload-list",
                    "filePath": "container/pages/ClaimDocumentUpload/ClaimDocumentUploadLayout",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "NHAIDocumentUploadList",
                    "path": '/nhai-document-upload-list',
                    "name": "nhai-document-upload-list",
                    "filePath": "container/pages/ClaimDocumentUpload/NhaiDocumentUploadLayout",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "NHIDCLDocumentUpload",
                    "path": '/nhidcl-document-upload-list',
                    "name": "nhidcl-document-upload-list",
                    "filePath": "container/pages/ClaimDocumentUpload/NhaiDocumentUploadLayout",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "NewClaimRegistration",
                    "path": '/new-claim-registration',
                    "name": "new-claim-registration",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "bajajAllianzInsurancePolicyVerification",
                    "path": '/bajaj-allianz-insurance-policy-verification',
                    "name": "bajaj-allianz-insurance-policy-verification",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "NHAIDocumentUpload",
                    "path": '/nhai',
                    "name": "nhai-document-upload",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "NHIDCL",
                    "path": '/nhidcl',
                    "name": "nhidcl-document-upload",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": "ClaimsStatus",
                    "path": '/claims-status',
                    "name": "claims-status",
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },
                {
                    "id": " ClaimsStatusView",
                    "path": '/claims-status-view',
                    "name": "claims-status-view",
                    "filePath": "container/pages/FieldValueList",
                    "auth" : true,
                    "children": [],
                    "roles":[]
                },

               

                

                {
                    "id": "UpdateClaimDetails",
                    "path": '/update-claim-details',
                    "name": 'updateclaimdetails',
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": ''
                    }
                },

                {
                    "id": "Reports",
                    "path": '/reports',
                    "name": 'reports',
                    "filePath": "container/pages/SingleFilter",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": ''
                    }
                },

                {
                    "id": "UpdateClaimDetail",
                    "path": '/update-claim-detail',
                    "name": "updateclaimdetail",
                    "filePath": "container/pages/UpdateClaimDetails/NewClaimRegistrationLayout",
                    "auth" : true,
                    "children": [{
                            "id": "UpdateClaimDetailCreate",
                            "path": '/update-claim-detail/:page_name',
                            "name": "UpdateClaimDetailCreate",
                            "filePath": "container/pages/UpdateClaimDetails/NewClaimRegistrationCreate",
                            "auth" : true,
                            "children": [],
                            "roles":[],
                            "meta": {
                                "breadcrumb": 'UpdateClaimDetailCreate'
                            }
                        }
                    ],
                    "roles":[]
                },


                {
                    "id": "New_Claim_Registration",
                    "path": '/new-claim-registration-create',
                    "name": "New_Claim_Registration",
                    "filePath": "container/pages/NewClaimRegistration/NewClaimRegistrationLayout",
                    "auth" : true,
                    "children": [{
                            "id": "New_Claim_Registration_Details",
                            "path": '/new-claim-registration-create/:page_name',
                            "name": "New_Claim_Registration_Details",
                            "filePath": "container/pages/NewClaimRegistration/NewClaimRegistrationCreate",
                            "auth" : true,
                            "children": [],
                            "roles":[],
                            "meta": {
                                "breadcrumb": 'New_Claim_Registration_Details'
                            }
                        }
                    ],
                    "roles":[]
                },
   
    
                
                {
                    "id": "ListOfClaims",
                    "path": '/list-of-claims',
                    "name": 'listofclaims',
                    "filePath": "views/pages/ListOfClaims.vue",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": ''
                    }
                },
                {
                    "id": "ClaimsStatus",
                    "path": '/claims-status',
                    "name": 'claimsstatus',
                    "filePath": "views/pages/ClaimDocumentUpload.vue",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": ''
                    }
                },
                {
                    "id": "ClaimsStatusDetail",
                    "path": '/claims-status-detail',
                    "name": 'claimsstatusdetail',
                    "filePath": "views/pages/ClaimStatusDetail.vue",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": ''
                    }
                },
               
                {
                    "id": "UpdateClaimDetailsForm",
                    "path": '/update-claim-details-form',
                    "name": 'updateclaimdetailsform',
                    "filePath": "views/pages/UpdateClaimDetailsForm.vue",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": ''
                    }
                },
                
                
               
                {
                    "id": "UserProfileUpdation",
                    "path": '/user-profile-updation',
                    "name": 'userprofileupdation',
                    "filePath": "views/pages/UserProfileUpdation.vue",
                    "auth" : true,
                    "children": [],
                    "roles":[],
                    "meta": {
                        "breadcrumb": ''
                    }
                }],
                "roles":[],
                "meta": {
                    "breadcrumb": ''
                },
                
            },
            {
                "id": "FORGOTPASSWORD",
                "path": "/reset-link/forgot-password",
                "name": "ForgotPassword",
                "filePath": "container/pages/ForgotPassword",
                "auth" : true,
                "roles":[],
                "meta": {
                    "breadcrumb": 'Deterrence'
                }
            },
            {
                "id": "RESETPASSWORD",
                "path": "/reset-link/reset-password/:userid",
                "name": "ResetPassword",
                "filePath": "container/pages/ResetPassword",
                "auth" : true,
                "roles":[],
                "meta": {
                    "breadcrumb": 'Deterrence'
                }
            },
            {
                "id": "404",
                "path": "*",
                "name": "404",
                "filePath": "components/pages/404",
                "auth" : true,
                "children": [],
                "meta": {
                    "breadcrumb": 'Home'
                }
            }
        ]
    }
}