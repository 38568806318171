<template>
<error-boundary>
  <div id="app">
    <router-view></router-view>
  </div>
  </error-boundary>
</template>

<script>
import ErrorBoundary from '@/components/common/ErrorBoundary.vue'
import { setTheme } from '@/apis/ApiDatas'
//import {EncodeString} from '@/shared/custom'
export default {
  name: 'App',
  methods : {
    toggleDarkLight() {
       var body = document.getElementById("body");
       var currentClass = body.className;
       body.className = currentClass == "dark-mode" ? "light-mode" : "dark-mode";
       this.$store.state.mode =  body.className
       this.saveTheme(body.className);
   },
   async saveTheme(themeMode){
      let { profile } = this.$store.state;
      let api = setTheme()
      let payload = {
            userId : profile.userId ? profile.userId : '',
            type : "THEMES",
            theme : (themeMode == "dark-mode") ? true : false 
      }
      //let encodedPayload = EncodeString(payload)
       //api.url = api.url + `?payload=${encodedPayload}`
      api.requests = payload
      api.success_message = ""
      await this.$store.dispatch("GetRequest", api) 
   }
  },
  components:{
     ErrorBoundary
  }
}
</script>
<style>
.form-control:invalid, .form-control.is-invalid{
   background: none !important;
}
</style>