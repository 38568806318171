import moment from 'moment'
const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];
  
  
function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
  
    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${ minutes }`;
    }
  
    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${ prefomattedDate } at ${ hours }:${ minutes }`;
    }
  
    if (hideYear) {
      // 10. January at 10:20
      return `${ day }. ${ month } at ${ hours }:${ minutes }`;
    }
  
    // 10. January 2017. at 10:20
    return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
}

export function convert_ddmmyy(date){
    let  _formate_Date = moment(new Date(date)).format("DD/MM/YYYY");
    return _formate_Date;
}


export function convert_datetime24(date){
    let  _formate_Date = moment(new Date(date)).format('MM/DD/YYYY h:mm:ss a')
    return _formate_Date;
}

export function convert_toiso(date){
    let _formate_Date = moment(new Date(date)).toISOString();
    return _formate_Date;
}

export function convert_multi_date(date) {
    let _formate_Date = moment.utc(date).local().toISOString();
    return _formate_Date;
}

export function convert_datetoiso(date){
    if(date){
        date = date.toString();
        date = date.split(' ')
    }
    else{
        date = ''
    }
    if(date && date.length>1){
        let _date = date[0].split('/').reverse()
        let updatedDate = `${_date} ${date[1]}`
        let _formate_Date = moment(new Date(updatedDate)).toISOString();
        return _formate_Date;
    }
    else if(date && date && date.length==1){
        let _date = date[0].split('/').reverse()
        let updatedDate = `${_date}`
        let _formate_Date = moment(new Date(updatedDate)).toISOString();
        return _formate_Date;
    }
    else {
        return date
    }
}



export function convert_difference(first_date, last_date,difference ='years'){
    let _first_date = moment(new Date(first_date)).format("DD-MM-YYYY HH:mm:ss");
    let _last_date = moment(new Date(last_date)).format("DD-MM-YYYY HH:mm:ss");
    var startDate = moment(_first_date, "DD-MM-YYYY HH:mm:ss");
    var endDate = moment(_last_date, "DD-MM-YYYY HH:mm:ss");
    if (difference == 'years') {
        return startDate.diff(endDate, difference);
    } else {
        return endDate.diff(startDate, difference);
    }
}


  
  
export function timeAgo(dateParam) {
    if (!dateParam) {
      return null;
    }
  
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);

    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
  
    console.log('timeAgo',seconds, minutes, isToday)
    if (seconds < 5) {
      return 'now';
    } else if (seconds < 60) {
      return `${ seconds } seconds ago`;
    } else if (seconds < 90) {
      return 'about a minute ago';
    } else if (minutes < 60) {
      return `${ minutes } minutes ago`;
    } else if (isToday) {
      return getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
      return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
    } else if (isThisYear) {
      return getFormattedDate(date, false, true); // 10. January at 10:20
    }
  
    return getFormattedDate(date); // 10. January 2017. at 10:20
  }

/* export function convert_difference_time(first_date, last_date,difference ='years'){
    let _first_date = moment(new Date(first_date)).format("DD-MM-YYYY HH:mm:ss");
    let _last_date = moment(new Date(last_date)).format("DD-MM-YYYY HH:mm:ss");
    var startDate = moment(_first_date, "DD-MM-YYYY HH:mm:ss");
    var endDate = moment(_last_date, "DD-MM-YYYY HH:mm:ss");
    if (difference == 'seconds') {
        return startDate.diff(endDate, 'seconds');
    } else {
        return endDate.diff(startDate, 'seconds');
    }
} */