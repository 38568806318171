<template>
  <div>
     <slot></slot>
  </div>
</template>

<script>
import {PostUiLogs} from '@/apis/Error'
export default {
  name: "error-boundary",
  props: {
    stopPropagation: Boolean
  },
  data() {
    return {
      err: false,
      vm: null,
      info: null
    };
  },
  async errorCaptured(err, vm, info) {
    this.err = err;
    this.vm = vm;
    this.info = info;
    let _temp_details = PostUiLogs()
    _temp_details.requests = {
      "id":"",
        "correlation":" ",
        "businessCorrelation":"",
        "apiName":"",
        "methodName":"",
        "action":"err",
        "request" : "",
        "error":err,
        "errorMessage":JSON.stringify({
            error_message : err.message,
            error_stack : err.stack,
        }),
        "pageCode":this.$route.params,
        "investigationId":" ",
        "taskId":" ",
        "userId":(this.$store.state && this.$store.state.profile) ? this.$store.state.profile.email : {} ,
        "createdDate": new Date(),
        "type": "UI",
    }
    // await this.$store.dispatch("GetRequest", _temp_details);
    return !this.stopPropagation;
  }
};
</script>