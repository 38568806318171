exports.vendorPayment = { 
    fieldGroupCode: 'professionalFees',
}

exports.reportMenus_constants = {
    report_pageCode : {
        pastHospital : 'past_hospital_visit',
    },
    report_sectionCode : {
        discharge_questionnaire : 'discharged_questionnaire',
        patient_visit_questionnaire : 'patient_visit_questionnaire'
    }
}

exports.Report_Pages_constants = {
    vendor_grading : 'Vendor Grading',
    trigger_questionnaire : 'Trigger Questionnaire',
    evidence_grid : 'Evidence Grid'
}